<template>
  <h1 class="cover-heading">
    {{ $t("notFound") }}
  </h1>
  <img
    :src="require(`@/assets/404.jpg`)"
    alt="404 image"
  >
</template>

<style scoped>
img {
  max-height: 65vw;
  width: auto;
}
</style>

export default { components: { Results } }
