import { createWebHistory, createRouter } from "vue-router";
import Test from "@/components/Test.vue";
import Home from "@/components/Home.vue";
import PassThrough from "@/components/PassThrough.vue";
import NotFound from "@/components/NotFound.vue";
import Boutique from "@/components/Boutique.vue";
import Alexy from "@/components/Alexy.vue";
import TestAlternatif from "@/components/TestAlternatif.vue";
import TestMichou from "@/components/TestMichou.vue";

const routes = [
    {
        path: "/:lang?",
        alias: '/',
        component: PassThrough,
        children: [{
                path: "",
                name: "home",
                component: Home,
            },
            {
                path: "test",
                name: "test",
                component: Test,
                props: true
            }
        ],
    },
    {       
        path: "/boutique",
        name: "boutique",
        component: Boutique,
    },
    {       
        path: "/testAlternatif",
        name: "testAlternatif",
        component: TestAlternatif,
    },
    {       
        path: "/testMichou",
        name: "testMichou",
        component: TestMichou,
    },
    {       
        path: "/alexy",
        name: "alexy",
        component: Alexy,
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
    }
];

const router = createRouter({
    history: createWebHistory(),
    mode: "history",
    routes,
});

export default router;