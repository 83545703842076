import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/bootstrap.min.css";
import "./assets/css/puritytest.css";
import {
    loadLocaleMessages,
    setI18nLanguage,
    setupI18n,
    SUPPORT_LOCALES,
} from "./i18n";
import LoadScript from "vue-plugin-load-script";
import { createHead } from '@vueuse/head'

const head = createHead()
const i18n = setupI18n({ locale: "fr" });
router.beforeEach((to, from, next) => {
    let language = to.params.lang;

    if (!language || !SUPPORT_LOCALES.includes(language)) {
        language = "fr";
    }

    // load locale messages
    loadLocaleMessages(i18n, language).then(() => {
        setI18nLanguage(i18n, language);
        next();
    });
});

const app = createApp(App);
app.use(head)
app.use(router);
app.use(i18n);
app.use(LoadScript);
app.config.globalProperties.i18n = i18n.global
app.mount("#app");
