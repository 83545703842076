<template>
  <div v-if="testInProgress">
    <p id="question">
      {{ currentQuestionItem.question }}
    </p>
    <div id="reponses">
      <div
        v-for="(points, value) in currentQuestionItem.answers"
        :key="value"
      >
        <p>
          <button
            class="btn-lg btn-primary"
            type="button"
            @click="submit(points)"
          >
            {{ value }}
          </button>
        </p>
      </div>
    </div>
  </div>
  <div v-else>
    <h3>Ton score</h3>
    <div
      id="res-score"
      class="score-container"
    > 
      <h2
        id="scoreNumber"
        class="score number"
        :class="scoreColor"
      >
        {{ resultats.total }}
      </h2>
    </div>
    <br>
    <p>Scores de 0 à 100</p>
    <div>
      <h3><u> Mesures recommandées</u></h3>
      <ul>
        <li 
          v-for="(item, index) in resultsPhrases" 
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import questionsTestMichou from "../michou-questionsData.json";

export default {
  name: "TestMichou",
  data() {
    return {
      resultats: {
        total: 0,
        social: 0,
        argent: 0,
      },
      questionIndex: 0,
      testInProgress: true,
      phrases : []
    };
  },
  computed : {
    /**
     * Determine score background color
     * 
     * @returns {String} css code string
     */
    scoreColor(){
      let score = this.resultats.total
      if(score < 25) {
        return 'label-danger'
      } else if (score < 50) {
         return 'label-warning'
      }else if (score < 75) {
         return 'label-primary'
      }
      return "label-success"
    },
    /**
     * Get the current question item
     * 
     * @returns {Object} question Item
     */
    currentQuestionItem(){
      return questionsTestMichou.testData[this.questionIndex]
    },
        /**
     * Calculate phrases to get
     * 
     * @returns {Array} array of string
     */
    resultsPhrases(){
      let index = this.resultats.total
      if(index <= 0) {
        index = 10
      } else if (index > 100) {
         index = 100
      }else {
        index = Math.ceil(index/10) *10
      }
      return questionsTestMichou.resultsGrid[index]
    }
  },
  methods: {
    /**
     * Move the test forward and add points
     * @params {Array} points to add [social,argent]
     * 
     * @returns {void}
     */
    submit(points){
      this.resultats.social = points[0]
      this.resultats.argent = points[1]

      if(this.questionIndex === questionsTestMichou.testData.length-1){
        this.testFinished()
      }else{
        this.questionIndex++
      }
      
    },
    /**
     * Calculate mean of social and argent
     * 
     * @returns {void}
     */
    testFinished(){
      this.testInProgress = false
      this.resultats.total = Math.round((this.resultats.social+this.resultats.argent)/2)
    },

  },
};
</script>
