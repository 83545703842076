<template>
  <div
    id="capture"
    style="background: #222"
  >
    <div
      ref="scoreBegin"
      class="score-info-container"
    >
      <h1 id="res-pureteMeter-1">
        {{ $t("results.global.you-are") }}&nbsp;
      </h1>
      <div
        id="res-pureteMeter"
        class="score-container"
      >
        <h1
          id="res-pureteMeter-2"
          class="score"
          :class="purityLevelColor"
        >
          {{ $t("results.purity-levels." + purityLevel) }}
        </h1>
      </div>
      <div
        id="res-score"
        class="score-container"
      >
        <h3 id="res-votreScore">
          {{ $t("results.global.your-score-is") }}&nbsp;
        </h3>
        <h3
          id="scoreNumber"
          class="score number"
          :class="scoreColor"
        >
          {{ score }}
        </h3>
        <h3 id="res-points">
          &nbsp;{{ $t("results.global.points") }}
        </h3>
      </div>
      <p id="moyenne-scores">
        {{
          $t("results.global.moyenne-line", {
            minScore: resultats.minimumScore,
            maxScore: resultats.maximumScore,
            moyenne: testStats.moyennes[$i18n.locale],
            testsNumber: testStats.testsNumber[$i18n.locale],
          })
        }}
      </p>
    </div>

    <div>
      <h3
        id="label-rep-pos"
        style="display: flex"
      >
        {{ $t("results.global.reponses-positives") }}
      </h3>
      <div id="cases-container">
        <div
          id="res-repAlcool"
          class="case-type"
          style="grid-column: 1; grid-row: 1"
          :class="cases.alcohol.color"
        >
          <img
            :src="require(`@/assets/results-icons/alcool-icon.png`)"
            alt="Icon Alcool"
            width="60"
            height="60"
            class="case-icon"
          >
          <div class="case-infos">
            <p class="case-label">
              {{ $t("results.category.alcohol") }}
            </p>
            <p
              id="rep-alcool-pourcent"
              class="case-percent"
            >
              {{ cases.alcohol.percentage }} %
            </p>
          </div>
        </div>

        <div
          id="res-repDrogue"
          class="case-type"
          style="grid-column: 2; grid-row: 1"
          :class="cases.drugs.color"
        >
          <img
            :src="require(`@/assets/results-icons/drogue-icon.png`)"
            alt="Icon Drogue"
            width="60"
            height="60"
            class="case-icon"
          >
          <div class="case-infos">
            <p class="case-label">
              {{ $t("results.category.drugs") }}
            </p>
            <p
              id="rep-drogue-pourcent"
              class="case-percent"
            >
              {{ cases.drugs.percentage }} %
            </p>
          </div>
        </div>

        <div
          id="res-repSexe"
          class="case-type"
          style="grid-column: 3; grid-row: 1"
          :class="cases.sex.color"
        >
          <img
            :src="require(`@/assets/results-icons/sexe-icon.png`)"
            alt="Icon Sexe"
            width="60"
            height="60"
            class="case-icon"
          >
          <div class="case-infos">
            <p class="case-label">
              {{ $t("results.category.sex") }}
            </p>
            <p
              id="rep-sexe-pourcent"
              class="case-percent"
            >
              {{ cases.sex.percentage }} %
            </p>
          </div>
        </div>

        <div
          id="res-repHygiene"
          class="case-type"
          style="grid-column: 1; grid-row: 2"
          :class="cases.hygiene.color"
        >
          <img
            :src="require(`@/assets/results-icons/hygiene-icon.png`)"
            alt="Icon Hygiene"
            width="60"
            height="60"
            class="case-icon"
          >
          <div class="case-infos">
            <p class="case-label">
              {{ $t("results.category.hygiene") }}
            </p>
            <p
              id="rep-hygiene-pourcent"
              class="case-percent"
            >
              {{ cases.hygiene.percentage }} %
            </p>
          </div>
        </div>

        <div
          id="res-repMorale"
          class="case-type"
          style="grid-column: 2; grid-row: 2"
          :class="cases.moral.color"
        >
          <img
            :src="require(`@/assets/results-icons/morale-icon.png`)"
            alt="Icon Morale"
            width="60"
            height="60"
            class="case-icon"
          >
          <div class="case-infos">
            <p class="case-label">
              {{ $t("results.category.moral") }}
            </p>
            <p
              id="rep-morale-pourcent"
              class="case-percent"
            >
              {{ cases.moral.percentage }} %
            </p>
          </div>
        </div>

        <div
          id="res-repPos"
          class="case-type"
          style="grid-column: 3; grid-row: 2"
          :class="cases.positive.color"
        >
          <img
            :src="require(`@/assets/results-icons/positif-icon.png`)"
            alt="Icon Positif"
            width="60"
            height="60"
            class="case-icon"
          >
          <div class="case-infos">
            <p class="case-label">
              {{ $t("results.category.positives") }}
            </p>
            <p
              id="rep-positif-pourcent"
              class="case-percent"
            >
              {{ cases.positive.percentage }} %
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      id="graphAndStats"
      style="display: inline-block; margin-top: 20px; width: 100%"
    >
      <div id="stats">
        <h4 id="phrase">
          {{ $t("results.phrases." + phraseLevel + "[0]") }}
        </h4>
        <p id="sous-phrase">
          {{ $t("results.phrases." + phraseLevel + "[1]") }}
        </p>
      </div>
      <div id="graphique">
        <canvas
          id="canva"
          style="width: 20px !important; height: 16px !important"
        />
      </div>
    </div>
    <!-- <div id="dl-res" style="margin-top:8px;margin-bottom:20px;">
        &nbsp;
        <a class="btn btn-lg btn-warning" id="res-photo" onClick="imageBuilder()" style="display:inline;padding: 8px 8px!important;">
            <img src='/images/results-icons/camera-icon.png' height='30px' width='30px'></a>
    </div>-->
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */

import Chart from "../assets/misc/Chart.bundle.min.js";
//import postscribe from "postscribe";
import axios from "axios";
//import Carousel from "./Carousel.vue";

export default {
  name: "Results",
  components: {
    //Carousel,
  },
  props: {
    resultats: Object,
    score: Number,
  },
  data() {
    return {
      purityLevel: "",
      purityLevelColor: "",
      scoreColor: "",
      cases: {
        positive: {
          percentage: Math.round(
            (this.resultats.positif.totalReponses /
              this.resultats.positif.totalQuestion) *
              100
          ),
          color: "",
        },
        alcohol: {
          percentage: Math.round(
            (this.resultats.alcool.totalReponses /
              this.resultats.alcool.totalQuestion) *
              100
          ),
          color: "",
        },
        drugs: {
          percentage: Math.round(
            (this.resultats.drogue.totalReponses /
              this.resultats.drogue.totalQuestion) *
              100
          ),
          color: "",
        },
        sex: {
          percentage: Math.round(
            (this.resultats.sexe.totalReponses /
              this.resultats.sexe.totalQuestion) *
              100
          ),
          color: "",
        },
        hygiene: {
          percentage: Math.round(
            (this.resultats.hygiene.totalReponses /
              this.resultats.hygiene.totalQuestion) *
              100
          ),
          color: "",
        },
        moral: {
          percentage: Math.round(
            (this.resultats.morale.totalReponses /
              this.resultats.morale.totalQuestion) *
              100
          ),
          color: "",
        },
      },
      phraseLevel: "",
      testStats: { moyennes: {}, testsNumber: {} },
    };
  },
  created() {
    this.purityDeterminator(this.score, 110);
    this.scoreColorDeterminator(this.score, this.resultats.maximumScore);

    //Cases color and percentages
    this.cases.positive.color = this.percentageToColor(
      this.cases.positive.percentage
    );
    this.cases.alcohol.color = this.percentageToColor(
      this.cases.alcohol.percentage
    );
    this.cases.drugs.color = this.percentageToColor(
      this.cases.drugs.percentage
    );
    this.cases.sex.color = this.percentageToColor(this.cases.sex.percentage);
    this.cases.hygiene.color = this.percentageToColor(
      this.cases.hygiene.percentage
    );
    this.cases.moral.color = this.percentageToColor(
      this.cases.moral.percentage
    );

    this.phraseLevelViaScore(this.score);
    const data = this.userDataBuilder();

    axios
      .post(process.env.VUE_APP_BACKEND_API + `/addUserStats`, data)
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  mounted() {
    window.ezstandalone = window.ezstandalone || {};
    // eslint-disable-next-line no-undef
    ezstandalone.cmd = ezstandalone.cmd || [];
    // eslint-disable-next-line no-undef
    ezstandalone.cmd.push(function() {
        // eslint-disable-next-line no-undef
        ezstandalone.displayMore(118);
    });
    axios
      .get(process.env.VUE_APP_BACKEND_API + `/testStats`)
      .then((response) => {
        this.testStats.moyennes = response.data.moyennes;
        this.testStats.testsNumber = response.data.testsNumber;
      })
      .catch((e) => {
        console.log(e);
      });

    this.genererGraph(
      this.cases.sex.percentage,
      this.cases.drugs.percentage,
      this.cases.alcohol.percentage,
      this.cases.hygiene.percentage,
      this.cases.moral.percentage
    );
    /*postscribe(
      "#social",
      `<script src="https://platform.twitter.com/widgets.js"><\/script>`
    );*/
    this.scrollToResults();
  },
  methods: {
    scrollToResults() {
      const el = this.$refs.scoreBegin;
      if (el && window.innerWidth < 900) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    userDataBuilder() {
      return {
        lang: this.$i18n.locale,
        score: this.score,
        categories: {
          sex: this.cases.sex.percentage,
          drugs: this.cases.drugs.percentage,
          alcohol: this.cases.alcohol.percentage,
          hygiene: this.cases.hygiene.percentage,
          moral: this.cases.moral.percentage,
        },
        date: new Date().toISOString(),
      };
    },
    /**
     * Determine le niveau de pureté ainsi que la couleur de fond
     */
    purityDeterminator(score, moyenne) {
      this.purityLevelColor = "label-success";
      if (score < 0) {
        // tres pur
        this.purityLevel = "tres-pur";
      } else if (score < moyenne / 2) {
        // pur
        this.purityLevel = "pur";
      } else if (score < moyenne) {
        // plutôt pur
        this.purityLevel = "plutot-pur";
      } else if (score < 150) {
        //impur
        this.purityLevelColor = "label-danger";
        this.purityLevel = "impur";
      } else if (score < 250) {
        // salement impur
        this.purityLevelColor = "label-danger";
        this.purityLevel = "salement-impur";
      } else if (score < 300) {
        // dangereux
        this.purityLevelColor = "label-danger";
        this.purityLevel = "dangereux-impur";
      } else {
        // ignoble
        this.purityLevelColor = "label-danger";
        this.purityLevel = "ignoble-impur";
      }
    },
    /**
     * Determine la couleur du fond du score
     */
    scoreColorDeterminator(score, maxScore) {
      const val = (score / maxScore) * 100;
      this.scoreColor = this.percentageToColor(val);
    },
    /**
     * Transforme un pourcentage en couleur (vert,bleu,jaune,rouge)
     */
    percentageToColor(percentage) {
      percentage = Math.round(percentage);
      if (percentage == 0) {
        return "label-success";
      } else if (percentage <= 33) {
        return "label-primary";
      } else if (percentage <= 66) {
        return "label-warning";
      } else {
        return "label-danger";
      }
    },
    phraseLevelViaScore(score) {
      if (score < 0) {
        this.phraseLevel = "negatif";
      } else if (score < 10) {
        this.phraseLevel = "10";
      } else if (score < 50) {
        this.phraseLevel = "50";
      } else if (score < 100) {
        this.phraseLevel = "100";
      } else if (score < 150) {
        this.phraseLevel = "150";
      } else if (score < 200) {
        this.phraseLevel = "200";
      } else if (score < 250) {
        this.phraseLevel = "250";
      } else if (score < 400) {
        this.phraseLevel = "400";
      } else {
        this.phraseLevel = "max";
      }
    },
    //Génération du graphique
    genererGraph(sexe, drogue, alcool, hygiene, morale) {
      var marksCanvas = document.getElementById("canva").getContext("2d");

      var marksData = {
        labels: [
          this.$t("results.category.sex"),
          this.$t("results.category.drugs"),
          this.$t("results.category.alcohol"),
          this.$t("results.category.hygiene"),
          this.$t("results.category.moral"),
        ],
        datasets: [
          {
            label: "Ton score",
            radius: 6,
            pointRadius: 6,
            pointBorderWidth: 3,
            pointBackgroundColor: "#d9534f",
            backgroundColor: "rgba(217, 83, 79,0.2)",
            borderColor: "#d9534f",
            data: [sexe, drogue, alcool, hygiene, morale],
          },
        ],
      };

      var options = {
        responsive: true,
        scale: {
          ticks: {
            callback: function (label) {
              return label + "        ";
            },
            beginAtZero: true,
            max: 100,
            showLabelBackdrop: false,
            fontColor: "#fff",
            labelOffset: 50,
            stepSize: 25,
          },
          pointLabels: {
            fontSize: 20,
            fontColor: "#fff",
          },
          gridLines: {
            circular: true,
            color: ["#3a3a3a", "#6f6f6f", "#aaaaaa", "#fff"],
            lineWidth: 0.5,
          },
          angleLines: {
            color: "#fff",
          },
        },
      };

      let radarChart = new Chart(marksCanvas, {
        type: "radar",
        data: marksData,
        options: options,
      });
      radarChart.options.legend.display = false;

      radarChart.options.tooltips.backgroundColor = "#d9534f";
      radarChart.options.tooltips.borderColor = "#d9534f";
    },
  },
};
</script>
