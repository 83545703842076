<template>
  <div id="boutique-menu">
    <div class="boutique-menu-item">
      <a
        style="display: grid"
        href="#!/c/0"
      >
        <span style="font-size: 18px">🏠</span>
        Accueil
      </a>
    </div>
    <div class="vertical-separator" />
    <div class="boutique-menu-item">
      <a
        style="display: grid"
        href="#!/~/account"
      >
        <span style="font-size: 18px">🧍</span>
        Mon Compte
      </a>
    </div>
    <div
      data-layout="MEDIUM_ICON_COUNTER"
      data-fixed-shape="RECT"
      data-icon="BASKET"
      class="ec-cart-widget"
    />
  </div>
  <hr>
  <div id="my-store-38930150" />
  <div />
  <div
    id="legal-mentions"
    style="display: grid"
  >
    <a
      href="https://griffor.company.site/pages/terms"
      target="_blank"
    >Conditions générales de ventes (CGV)</a>&nbsp;
    <a
      href="https://griffor.company.site/pages/about"
      target="_blank"
    >Mentions légales et politique de confidentialité</a>
  </div>
</template>

<style scoped>
img {
  max-height: 65vw;
  width: auto;
}
</style>

<script>
export default {
  mounted() {
    this.$loadScript(
      "https://app.ecwid.com/script.js?38930150&data_platform=code&data_date=2021-10-17"
    )
      .then(() => {
        // eslint-disable-next-line no-undef
        Ecwid.init();
        // eslint-disable-next-line no-undef
        xProductBrowser(
          "categoriesPerRow=3",
          "views=grid(20,3) list(60) table(60)",
          "categoryView=grid",
          "searchView=list",
          "id=my-store-38930150"
        );
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
};
</script>
