<template>
  <div v-if="questionIndex < resultats.positif.totalQuestion">
    <p id="question">
      {{ $t("questions[" + questionIndex + "].question") }}
    </p>
    <div
      v-if="question.optionText"
      v-html="question.optionText"
    />
    <div id="reponses">
      <div
        v-for="(points, value) in question.answers"
        :key="value"
      >
        <p v-if="value === 'oui'">
          <button
            class="btn-lg btn-success"
            type="button"
            @click="submit(points)"
          >
            {{ $t("possibleAnswers.oui") }}
          </button>
        </p>
        <p v-else-if="value === 'non'">
          <button
            class="btn-lg btn-danger"
            type="button"
            @click="submit(points)"
          >
            {{ $t("possibleAnswers.non") }}
          </button>
        </p>
        <p v-else-if="value === '0'">
          <button
            class="btn-lg btn-primary"
            type="button"
            @click="submit(points)"
          >
            {{ value }}
          </button>
        </p>
        <p
          v-else
          type="button"
        >
          <button
            class="btn-lg btn-danger"
            @click="submit(points)"
          >
            {{ value }}
          </button>
        </p>
      </div>
    </div>
  </div>
  <div v-else>
    <results
      :resultats="resultats"
      :score="score"
    />
  </div>
</template>

<script>
import Results from "./Results.vue";
import questionsInformations from "../griffor-questionsData.json";

const currentQuestion = {
  id: "",
  answers: {},
  types: [],
  optionText: "",
};

export default {
  name: "App",
  components: {
    Results,
  },
  props: {
    savedTestState: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      testState: {},
      resultats: {
        positif: { totalQuestion: 0, totalReponses: 0 },
        sexe: { totalQuestion: 0, totalReponses: 0 },
        alcool: { totalQuestion: 0, totalReponses: 0 },
        drogue: { totalQuestion: 0, totalReponses: 0 },
        hygiene: { totalQuestion: 0, totalReponses: 0 },
        morale: { totalQuestion: 0, totalReponses: 0 },
        maximumScore: 0,
        minimumScore: 0,
      },
      score: 0,
      questionIndex: 0,
      hiddenQuestions: [],
      question: this.loadQuestion(0),
    };
  },
  mounted() {
    window.ezstandalone = window.ezstandalone || {};
    // eslint-disable-next-line no-undef
    ezstandalone.cmd = ezstandalone.cmd || [];
    // eslint-disable-next-line no-undef
    ezstandalone.cmd.push(function() {
        // eslint-disable-next-line no-undef
        ezstandalone.displayMore(116, 117);
    });
  },
  created() {
    this.testState = this.savedTestState;
    if (this.testState) {
      this.testState = JSON.parse(this.savedTestState);
      this.resultats = this.testState.resultats;
      this.score = this.testState.score;
      this.questionIndex = this.testState.questionIndex;
      this.hiddenQuestions = this.testState.hiddenQuestions;
      this.question = this.testState.question;
    } else {
      this.init();
    }
    this.displayState();
  },
  methods: {
    loadQuestion(index) {
      let currentId = this.$t("questions[" + index + "].id");
      currentQuestion.id = currentId;

      while (
        this.hiddenQuestions !== undefined &&
        this.hiddenQuestions.includes(currentId)
      ) {
        if (process.env.VUE_APP_DEV === "true")
          console.log("SKIPPING : " + currentId);
        this.hiddenQuestions.splice(this.hiddenQuestions.indexOf(currentId), 1);
        index++;
        currentId = this.$t("questions[" + index + "].id");
      }

      currentId = this.$t("questions[" + index + "].id");
      currentQuestion.id = currentId;
      currentQuestion.answers = questionsInformations[currentId].answers;
      currentQuestion.types = questionsInformations[currentId].types;
      currentQuestion.optionText = questionsInformations[currentId].optionText;
      currentQuestion.ifNoDontShow =
        questionsInformations[currentId].ifNoDontShow;

      this.questionIndex = index;
      return currentQuestion;
    },
    submit(points) {
      const idsToHide = currentQuestion.ifNoDontShow;
      if (idsToHide !== undefined && points === 0) {
        this.hiddenQuestions = this.hiddenQuestions.concat(idsToHide);
      }
      this.questionIndex++;
      if (points != 0) {
        this.pointsCalculator(points, this.question.types);
      }
      //récuperation de l'id depuis la question actuelle (i18n)
      if (this.questionIndex < this.resultats.positif.totalQuestion) {
        this.question = this.loadQuestion(this.questionIndex);
        this.displayState();
      }
      this.saveStateToLocalStorage();
    },
    /**
     * Update and save state
     */
    saveStateToLocalStorage() {
      const date = new Date();
      const todayDate =
        date.getDay() + ":" + date.getMonth() + ":" + date.getFullYear();
      this.testState = JSON.stringify({
        resultats: this.resultats,
        score: this.score,
        questionIndex: this.questionIndex,
        hiddenQuestions: this.hiddenQuestions,
        question: this.question,
        date: todayDate,
      });
      localStorage.testState = this.testState;
    },
    /**
     * Add points to a category AND global category
     */
    pointsCalculator(points, types) {
      this.score += points;
      types.forEach((type) => {
        switch (type) {
          case "sexe":
            this.resultats.sexe.totalReponses++;
            break;
          case "alcool":
            this.resultats.alcool.totalReponses++;
            break;
          case "drogue":
            this.resultats.drogue.totalReponses++;
            break;
          case "hygiene":
            this.resultats.hygiene.totalReponses++;
            break;
          case "morale":
            this.resultats.morale.totalReponses++;
            break;
          default:
        }
      });
      this.resultats.positif.totalReponses++;
    },
    /**
     * Display the state of the Test in the console
     */
    displayState() {
      if (process.env.VUE_APP_DEV === "true") {
        console.log(
          "Question : " +
            this.questionIndex +
            " / " +
            this.resultats.positif.totalQuestion +
            " Intitulé : " +
            this.$t("questions[" + this.questionIndex + "].question") +
            "\nType(s): " +
            this.question.types +
            "\nChoix: " +
            JSON.stringify(this.question.answers) +
            "\n---------------------------------------------------" +
            "\nSexe : ",
          this.resultats.sexe.totalReponses +
            " / " +
            this.resultats.sexe.totalQuestion,
          "\nAlcool : ",
          this.resultats.alcool.totalReponses +
            " / " +
            this.resultats.alcool.totalQuestion,
          "\nDrogue : ",
          this.resultats.drogue.totalReponses +
            " / " +
            this.resultats.drogue.totalQuestion,
          "\nHygiene : ",
          this.resultats.hygiene.totalReponses +
            " / " +
            this.resultats.hygiene.totalQuestion,
          "\nMorale : ",
          this.resultats.morale.totalReponses +
            " / " +
            this.resultats.morale.totalQuestion,
          "\nScore : ",
          this.score,
          "\nMax Score possibles : ",
          this.resultats.maximumScore
        );
      }
    },
    /**
     * Initialize the tests : resultats object
     */
    init() {
      //count questions per type AND total
      let maxScore = 0;
      let minScore = 0;
      Object.values(questionsInformations).forEach((question) => {
        let arr = Object.values(question.answers);
        maxScore += Math.max(...arr);
        minScore += Math.min(...arr);

        const types = question.types;
        types.forEach((type) => {
          switch (type) {
            case "sexe":
              this.resultats.sexe.totalQuestion++;
              break;
            case "alcool":
              this.resultats.alcool.totalQuestion++;
              break;
            case "drogue":
              this.resultats.drogue.totalQuestion++;
              break;
            case "hygiene":
              this.resultats.hygiene.totalQuestion++;
              break;
            case "morale":
              this.resultats.morale.totalQuestion++;
              break;
            default:
          }
        });
        this.resultats.positif.totalQuestion++;
        this.resultats.maximumScore = maxScore;
        this.resultats.minimumScore = minScore;
      });
    },
  },
};
</script>
