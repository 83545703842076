<template>
  <h1 class="cover-heading">
    COUCOU
  </h1>
  <img
    src="/images/alexy.jpg"
    alt="Alexy"
  >
</template>

<style scoped>
img {
  max-width: 500px;
  width: 90vw;
}
</style>

export default { components: { Alexy } }
