<template>
  <div class="site-wrapper-inner">
    <div class="cover-container">
      <div
        id="header-menu"
        class="masthead clearfix"
      >
        <Menu />
      </div>
      <div class="inner cover">
        <router-view />
      </div>
      <div
        id="footer"
        class="mastfoot"
      >
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";
import { useHead } from "@vueuse/head";
import { computed } from "vue";
import { getCurrentInstance } from "vue";

export default {
  name: "App",
  components: {
    Menu,
    Footer,
  },
  setup() {
    const app = getCurrentInstance();
    const i18n = app.appContext.config.globalProperties.i18n;

    useHead({
      // Can be static or computed
      title: computed(() => i18n.t("head.meta.title")),
      meta: [
        {
          name: `description`,
          content: computed(() => i18n.t("head.meta.description")),
        },
        {
          name: `apple-mobile-web-app-title`,
          content: computed(() => i18n.t("head.meta.title")),
        },
        {
          name: `application-name`,
          content: computed(() => i18n.t("head.meta.title")),
        },
        {
          name: `twitter:title`,
          content: computed(() => i18n.t("head.meta.title")),
        },
        {
          name: `twitter:description`,
          content: computed(() => i18n.t("head.meta.description")),
        },
        {
          name: `og:title`,
          content: computed(() => i18n.t("head.meta.title")),
        },
        {
          name: `og:description`,
          content: computed(() => i18n.t("head.meta.description")),
        },
      ],
    });
  },
  data() {
    return {
      route: this.$route,
    };
  },
  mounted() {
    window.ezstandalone = window.ezstandalone || {};
    // eslint-disable-next-line no-undef
    ezstandalone.cmd = ezstandalone.cmd || [];
    // eslint-disable-next-line no-undef
    ezstandalone.cmd.push(function() {
        // eslint-disable-next-line no-undef
        ezstandalone.displayMore(112, 113);
    });
  },
};
</script>
